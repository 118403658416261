<template>
    <v-container fluid>
        <v-dialog v-model="deleted.dialog" max-width="600" v-if="deleted.dialog">
            <v-card class="p-5">
                <v-card-title>Вы уверены, что хотите удалить запись</v-card-title>
                <v-card-text class="mt-5">
                    <v-btn color="error" @click="confirm_delete_item">
                        Удалить запись
                    </v-btn>
                </v-card-text>
            </v-card>
        </v-dialog>
        <v-dialog v-model="create.dialog" max-width="600" v-if="create.dialog">
            <v-card class="p-5">
                <v-card-title>Добавление расхода</v-card-title>
                <v-card-text>
                    <v-autocomplete
                        v-model="create.form_data.brand" :items="brand_choices" class="mt-3"
                        label="Бренд" outlined hide-details dense clearable item-text="name" item-value="name"
                    ></v-autocomplete>
                    <v-text-field
                        v-model="create.form_data.planned_videos" label="Плановое кол-вл видео" 
                        outlined clearable dense class="mt-3" hide-details type="number"
                    ></v-text-field>
                    <v-text-field
                        v-model="create.form_data.planned_expenses" label="Плановый расход" 
                        outlined clearable dense class="mt-3" hide-details type="number"
                    ></v-text-field>
                    <v-text-field
                        v-model="create.form_data.fact_expenses" label="Фактический расход" 
                        outlined clearable dense class="mt-3" hide-details type="number"
                    ></v-text-field>
                    <v-menu 
                        v-model="create.date_picker" :close-on-content-click="false" 
                        :nudge-right="40" transition="scale-transition" offset-y="offset-y" 
                        min-width="290px" ref="date_picker"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                clearable @click:clear="create.form_data.date = null" dense v-on="on"
                                v-model="create.form_data.date" label="Дата" readonly="readonly" v-bind="attrs" 
                                hide-details="auto" outlined class="mt-3"
                            ></v-text-field>
                        </template>
                        <v-date-picker
                            v-model="create.form_data.date" :first-day-of-week="1"
                            @input="create.date_picker = false" type="month"
                        ></v-date-picker>
                    </v-menu>
                    <v-btn 
                        color="primary" class="mt-5" :loading="create.loading" @click="create_record"
                    >Добавить расход</v-btn>
                </v-card-text>
            </v-card>
        </v-dialog>
        <v-dialog v-model="edit.dialog" max-width="600" v-if="edit.dialog">
            <v-card class="p-5">
                <v-card-title>Изменение расхода</v-card-title>
                <v-card-text>
                    <v-autocomplete
                        v-model="edit.form_data.brand" :items="brand_choices" class="mt-3"
                        label="Бренд" outlined hide-details dense clearable item-text="name" item-value="name"
                    ></v-autocomplete>
                    <v-text-field
                        v-model="edit.form_data.planned_videos" label="Плановое кол-вл видео" 
                        outlined clearable dense class="mt-3" hide-details type="number"
                    ></v-text-field>
                    <v-text-field
                        v-model="edit.form_data.planned_expenses" label="Плановый расход" 
                        outlined clearable dense class="mt-3" hide-details type="number"
                    ></v-text-field>
                    <v-text-field
                        v-model="edit.form_data.fact_expenses" label="Фактический расход" 
                        outlined clearable dense class="mt-3" hide-details type="number"
                    ></v-text-field>
                    <v-btn 
                        color="primary" class="mt-5" :loading="edit.loading" @click="update_record"
                    >Изменить расход</v-btn>
                </v-card-text>
            </v-card>
        </v-dialog>
        <v-card>
            <v-card-title>
                Расходы социальных сетей
                <v-btn class="ml-5" color="primary" @click="create.dialog = true">
                    Добавить расход
                </v-btn>
            </v-card-title>
            <v-card-text ref="social_items_filters">
                <v-data-table
                    :headers="list.headers" :pageCount="list.pagination.numberOfPages" :items="list.data"
                    item-key="id" :loading="list.loading" :options.sync="list.options"
                    :server-items-length="list.pagination.totalItems" class="elevation-1 mt-5"
                    :footer-props="{'items-per-page-options': [25, 50, 100]}"
                >
                    <template v-slot:item.planned_expenses="{ item }">
                       {{ item.planned_expenses | toRub }}
                    </template>
                    <template v-slot:item.fact_expenses="{ item }">
                       {{ item.fact_expenses | toRub }}
                    </template>
                    <template v-slot:item.action="{ item }">
                        <div class="d-flex justify-center align-center">
                            <v-btn icon @click="open_dialog_edit(item)"> 
                                <v-icon>mdi-pencil</v-icon>
                            </v-btn>
                            <v-btn icon class="ml-3" @click="open_dialog_delete(item)">
                                <v-icon>mdi-delete</v-icon>
                            </v-btn>
                        </div>
                    </template>
                </v-data-table>
            </v-card-text>
        </v-card>
    </v-container>
</template>

<script>
    import { SocialParser } from '@/api/social_parser';

	export default {
		name: 'SocialParserFinance',
		data() {
			return {
                deleted: {
                    dialog: false,
                    deleted_item: null
                },
                create: {
                    dialog: false,
                    loading: false,
                    form_data: {
                        brand: null,
                        planned_expenses: null,
                        fact_expenses: null,
                        planned_videos: null,
                        date: null,
                    },
                    date_picker: false
                },
                edit: {
                    editable_item: null,
                    dialog: false,
                    loading: false,
                    form_data: {
                        brand: null,
                        planned_expenses: null,
                        fact_expenses: null,
                        planned_videos: null
                    },
                },
                list: {
                    loading: false,
                    headers: [
						{ text: 'Бренд', value: 'brand', width: '1%', sortable: true, align: 'center'},
                        { text: 'Дата', value: 'date', width: '1%', sortable: true, align: 'center'},
                        { text: 'Количество видео', value: 'planned_videos', width: '1%', sortable: true, align: 'center' },
						{ text: 'Плановый расход', value: 'planned_expenses', width: '1%', sortable: true, align: 'center' },
                        { text: 'Фактический расход', value: 'fact_expenses', width: '1%', sortable: true, align: 'center' },
						{ text: 'Действия', value: 'action', width: '1%', sortable: false, align: 'center' },
					],
                    data: [],
                    options: {
                        sortBy: ['date'],
                        sortDesc: [true],
                        itemsPerPage: 50
                    },
                    pagination: {
                        next: null,
                        totalItems: 0,
                        numberOfPages: 0,
                        currentPage: 0
                    },
                },
                brand_choices: [],
            }
		},
        watch: {
            'list.options': async function (newVal, oldVal) {
				if (oldVal) {
					await this.list_records()
				}
			},
        },
		methods: {
            open_dialog_edit(item){
                this.edit.editable_item = item
                this.edit.dialog = true
                this.edit.form_data.planned_expenses = item.planned_expenses
                this.edit.form_data.fact_expenses = item.fact_expenses
                this.edit.form_data.planned_videos = item.planned_videos
                this.edit.form_data.brand = item.brand
                this.deleted.deleted_item = item
            },
            open_dialog_delete(item){
                this.deleted.dialog = true
                this.deleted.deleted_item = item
            },           
            async fetch_brand_list(){
				try {
                    const response = await SocialParser.BrandListSocialFinance()
					this.brand_choices = response.data
				} catch (err) {
					console.log(err.data)
				}
            },
            async list_records(){
                this.list.loading = true
				this.$vuetify.goTo(this.$refs.social_items_filters, {
                    duration: 500, offset: 150, easing: 'linear',
                })
				const { page, itemsPerPage, sortBy, sortDesc } = this.list.options
				let pageNumber = page - 1
				try {
					let query_data = {
						'offset': itemsPerPage * pageNumber,
						'limit': itemsPerPage,
						'sort': sortDesc[0] ? `-${sortBy[0]}` : sortBy[0]
					}
                    const response = await SocialParser.ListSocialFinance(query_data)
					this.list.data = response.data.results
					this.list.pagination.totalItems = response.data.count
					this.list.pagination.numberOfPages = Math.ceil(response.data.count / itemsPerPage)
				} catch (err) {
					console.log(err.data)
				}
				this.list.loading = false
            },
            async confirm_delete_item(){
                try{
                    await SocialParser.DeleteSocialFinance(this.deleted.deleted_item.id)
                    this.$toasted.show(`Запись удалена`, {
                        theme: 'bubble', type: 'success', position: 'top-center', duration: 3000,
                    });
                    await this.list_records()
                } catch (err){
                    this.$toasted.show(`Возникла ошибка`, {
						theme: 'bubble', type: 'error', position: 'top-center', duration: 3000,
                    });
                    console.log(err)
                }
                this.deleted.dialog = false
            },
            async create_record(){
                this.create.loading = true
                try{
                    await SocialParser.CreateSocialFinance(this.create.form_data)
                    this.$toasted.show(`Запись создана`, {
                        theme: 'bubble', type: 'success', position: 'top-center', duration: 3000,
                    });
                    this.create.dialog = false
                    await this.list_records()
                } catch (err){
                    this.$toasted.show(`Возникла ошибка(${err.response.data.error})`, {
						theme: 'bubble', type: 'error', position: 'top-center', duration: 3000,
                    });
                    console.log(err)
                }
                this.create.loading = false
            },
            async update_record(){
                this.edit.loading = true
                try{
                    await SocialParser.UpdateSocialFinance(this.edit.editable_item.id, this.edit.form_data)
                    this.$toasted.show(`Запись создана`, {
                        theme: 'bubble', type: 'success', position: 'top-center', duration: 3000,
                    });
                    this.edit.dialog = false
                    await this.list_records()
                } catch (err){
                    this.$toasted.show(`Возникла ошибка(${err.response.data.error})`, {
						theme: 'bubble', type: 'error', position: 'top-center', duration: 3000,
                    });
                    console.log(err)
                }
                this.edit.loading = false
            },
		},
		async mounted() {
			document.title = 'Расходы социальных сетей';
			this.$store.commit('setPageTitle', `Расходы социальных сетей`)
            await this.fetch_brand_list()
		},
	};
</script>

<style lang="scss">
</style>