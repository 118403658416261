<template>
    <div>
		<v-dialog v-model="dialog_charts" max-width="100%" v-if="dialog_charts">
			<v-card>
				<v-card-title>График</v-card-title>
				<v-card-text class="mt-5">
					<ChartsProductData 
						:filters="{...transfromFiltersForQuery}"
						ref="chart_products"
					/>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="blue darken-1" text @click="dialog_charts=false">
						Закрыть
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-dialog v-model="dialog_stats_excel" max-width="400px" v-if="dialog_stats_excel">
			<v-card>
				<v-card-title>Импорт данных продукта из xlsx</v-card-title>
				<v-card-text class="mt-5">
					<v-file-input
						label="File input"
						outlined
						dense
						id="upload_input_stats_product"
						class="pt-5"
						hide-details
					></v-file-input>
					<p 
						style="cursor: pointer;"
						class="text-download mt-3"
						@click="downloadTempate(is_one=true)"
					>Скачать шаблон</p>
				</v-card-text>
				<v-card-actions>
					<v-btn 
						color="blue darken-1" 
						text 
						@click="uploadFromExcel"
						:loading='loading_upload'
					>
						Импортировать
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-dialog v-model="update_stats_excel.dialog" v-if="update_stats_excel.dialog" max-width="450px">
			<v-card>
				<v-card-title>Обновление данных продукта из xlsx</v-card-title>
				<v-card-text class="mt-5">
					<v-file-input
						label="File input"
						outlined
						dense
						id="upload_update_stats_product"
						class="pt-5"
						hide-details
					></v-file-input>
					<v-menu 
						v-model="datepicker_date" 
						:close-on-content-click="false" 
						transition="scale-transition" 
						offset-y="offset-y"
						nudge-right="40" 
						min-width="290px" 
						ref="datepicker_date"
					>
						<template v-slot:activator="{ on, attrs }">
							<v-text-field
								clearable
								@click:clear="update_stats_excel.date_from = null"
								v-model="dateTransform"
								label="Дата"
								readonly="readonly"
								class="mt-3"
								v-bind="attrs"
								outlined
								dense
								v-on="on"
							>  
							</v-text-field>
						</template>
						<v-date-picker 
							v-model="update_stats_excel.date_from" 
							@input="datepicker_date = false"
							:first-day-of-week="1"
						></v-date-picker>
					</v-menu>
					<v-checkbox
						v-model="update_stats_excel.flag_one_day"
						class="mt-5"
						label="Только за конкеретную дату"
					></v-checkbox>
					<p 
						style="cursor:pointer"
						class="text-download mt-3"
						@click="downloadTempate(is_one=false)"
					>Скачать шаблон</p>
				</v-card-text>
				<v-card-actions>
					<v-btn 
						color="blue darken-1" 
						text 
						@click="uploadUpdatedStatsFromExcel"
					>
						Импортировать
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-dialog v-model="dialog_monitoring" max-width="500px" v-if="dialog_monitoring">
			<v-card>
				<v-card-title>Добавление ключей</v-card-title>
				<v-card-text class="mt-5">
					<v-textarea
					solo
					auto-grow
					rows="3"
					v-model="key_list_monitoring"
					label="Введите название ключей (каждый ключ с новой строки)"
					></v-textarea>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="blue darken-1" text @click="closeWindowMonitoring">Закрыть</v-btn>
					<v-btn color="blue darken-1" text @click="saveKeyword">Добавить</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
        <v-navigation-drawer 
			v-model="drawer" v-if="drawer" temporary absolute app
        >
			<div class="pa-4">
				<v-subheader>Рейтинг</v-subheader>
					<v-range-slider
							v-model="filters.product_rating_range"
							ticks="always"
							:tick-size="5"
							max="5"
							min="0"
					></v-range-slider>
					<div>От {{filters.product_rating_range[0]}} до {{filters.product_rating_range[1]}}</div>
			</div>
            <v-divider></v-divider>
            <div class="pa-4">
				<v-select
					v-model="filters.account" :items="filter_choices.wbaccount" item-text="name"
					item-value="id" label="ЛК" outlined multiple hide-details dense clearable
				></v-select>
            </div>
            <div class="pa-4">
				<v-autocomplete
					v-model="filters.manager" :items="filter_choices.product_manager_choices"
					label="Менеджер" outlined hide-details dense clearable multiple item-text="name" item-value="id"
				></v-autocomplete>
            </div>
            <div class="pa-4">
				<v-select
					v-model="filters.profile" :items="filter_choices.product_profile_choices"
					item-text="name" item-value="value" label="Профильность" outlined
					multiple hide-details dense clearable
				></v-select>
            </div>
            <div class="pa-4">
				<v-text-field
					v-model="filters.tag" clearable outlined dense label="Тег" hide-details="hide-details">
				</v-text-field>
            </div>
			<div class="pa-4">
				<v-text-field
					v-model="filters.object" clearable outlined dense label="Предмет" hide-details="hide-details">
				</v-text-field>
            </div>
            <div class="pa-4">
				<v-switch
					v-model="filters.advertising" inset class="mt-0 mb-2" hide-details :label="`Рекламные`"
				></v-switch>
            </div>
			<div class="px-4">
				<v-switch
					v-model="filters.is_bookmark" inset class="mt-0 mb-2" hide-details :label="`Избранные`"
				></v-switch>
            </div>
			<div class="pa-4">
				<v-radio-group
					v-model="filters.stock_fbs" hide-details column class="mt-1"
				>
					<v-radio label="Все" :value=0 ></v-radio>
					<v-radio label="Есть остатки FBS" :value=1></v-radio>
					<v-radio label="Нет остатков FBS" :value=-1></v-radio>
				</v-radio-group>
			</div>
            <div class="pa-5">
                <v-btn
					@click="fetchFeedbacksWithFilters()" depressed color="purple darken-2"
					class="mb-3" dark
                >Применить</v-btn>
                <v-btn @click="clearFilters()" depressed class="mb-10">Сбросить</v-btn>
            </div>
        </v-navigation-drawer>
        <v-sheet ref="product_table_filters">
			<v-btn icon @click="drawer = !drawer">
                <v-icon>mdi-filter-variant</v-icon>
            </v-btn>
            <v-container fluid>
                <v-row class="align-center">
					<v-col>
						<v-select
							v-model="filters.brand"
							:items="filter_choices.product_brand_choices"
							item-text="name"
							item-value="name"
							label="Бренд"
							outlined
							multiple
							hide-details
							dense
							clearable
						></v-select>
						<v-select
							v-model="filters.group"
							:items="filter_choices.product_group_choices"
							item-text="name"
							item-value="value"
							label="Группа"
							class="mt-2"
							outlined
							multiple
							hide-details
							dense
							clearable
						></v-select>
					</v-col>
					<v-col>
						<v-select
							v-model="filters.status"
							:items="filter_choices.product_status_choices"
							item-text="name"
							item-value="value"
							label="Статус"
							outlined
							multiple
							hide-details
							dense
							clearable
						></v-select>
						<div class="mt-2 d-flex align-center">
							<v-select
								v-model="filters.abc_status"
								:items="[{'name': 'A'}, {'name': 'B'}, {'name': 'C'}, {'name': 'D'}]"
								item-text="name"
								item-value="name"
								label="Статус ABC"
								outlined
								multiple
								hide-details
								dense
								clearable
							></v-select>
							<v-tooltip bottom max-width=350>
								<template v-slot:activator="{ on, attrs }">
									<span v-bind="attrs" v-on="on" style="cursor: pointer">
										<v-icon small class="ml-1">mdi-help-circle-outline</v-icon>
									</span>
								</template>
								<span>
									Товары с накопительной долей по ВП_МП:<br>
									до 60% относятся к классу А<br>
									от 60% до 80% к классу B<br>
									от 80% до 95% относятся к классу C<br>
									остальное к классу D
								</span>
							</v-tooltip>
						</div>
					</v-col>

					<v-col>
						<v-switch
                            v-model="filters.is_empty_expenses" hide-details 
                            label="Без расходов"
                        ></v-switch>
                    </v-col>
                    <v-col>
                      <v-radio-group
                        v-model="filters.stock"
                        hide-details
                        row
                        class="mt-1"
                      >
                        <v-radio
                            label="Все"
                            :value=0
                        ></v-radio>
                        <v-radio
                            label="Есть остатки FBO"
                            :value=1
                        ></v-radio>
                        <v-radio
                            label="Нет остатков FBO"
                            :value=-1
                        ></v-radio>
                      </v-radio-group>
                    </v-col>
					<v-col>
                      <v-radio-group v-model="filters.logistic" hide-details column class="mt-1">
                        <v-radio label="Все" :value=0></v-radio>
						<v-radio label="Логистика FBS" :value="1"
						></v-radio>
						<v-radio label="Логистика FBO" :value="-1"></v-radio>
						<v-radio label="Ручная логистика" :value="2"></v-radio>
                      </v-radio-group>
                    </v-col>
                    <v-col>
                      <v-radio-group
                        v-model="filters.rentab"
                        hide-details
                        row
                        class="mt-1"
                      >
                        <v-radio
                            label="Все"
                            :value=0
                        ></v-radio>
                        <v-radio
                            label="Марж-ть больше 0"
                            :value=1
                        ></v-radio>
                        <v-radio
                            label="Марж-ть меньше 0"
                            :value=-1
                        ></v-radio>
                      </v-radio-group>
                    </v-col>
                    <v-col>
                      <v-radio-group
                        v-model="filters.cost_price"
                        hide-details
                        row
                        class="mt-1"
                      >
                        <v-radio
                            label="Все"
                            :value=0
                        ></v-radio>
                        <v-radio
                            label="Себестоимость больше 0"
                            :value=1
                        ></v-radio>
                        <v-radio
                            label="Себестоимость 0"
                            :value=-1
                        ></v-radio>
                      </v-radio-group>
                    </v-col>
					<v-col>
                      <v-radio-group
                        v-model="filters.stock_day"
                        hide-details
                        row
                        class="mt-1"
                      >
                        <v-radio
                            label="Все"
                            :value=0
                        ></v-radio>
                        <v-radio
                            label="Остаток дней больше 60"
                            :value=1
                        ></v-radio>
						<v-radio
                            label="Остаток дней c 1c больше 60"
                            :value=2
                        ></v-radio>
                      </v-radio-group>
                    </v-col>
                    <v-col>
                        <v-text-field
							v-model="filters.name"
							clearable
							outlined
							dense
							label="Название товара"
							hide-details="hide-details">
                        </v-text-field>
                    </v-col>
					<v-col>
						<v-text-field
							v-model="filters.code_1c"
							clearable
							outlined
							dense
							label="Артикул 1С(через запятую)"
							hide-details="hide-details">
                        </v-text-field>
						<v-text-field
							v-model="filters.nmid"
							class="mt-2"
							clearable
							outlined
							dense
							label="Артикул(через запятую)"
							hide-details="hide-details">
                        </v-text-field>
					</v-col>
                </v-row>
                <v-row justify="end">
                    <v-col md="4" class="text-right py-0">
						<v-btn
                                @click="fetchFeedbacksWithFilters()"
                                depressed
                                color="purple darken-2"
                                class="mb-3 ml-3"
                                dark
                        >Применить</v-btn>
                    </v-col>
                </v-row>
            </v-container>
        </v-sheet>
        <v-data-table
			:headers="is_root_display ? dataTable.headers_with_group: dataTable.headers"
			:pageCount="pagination.numberOfPages"
			:items="items"
			item-key="id"
			:loading="tableLoading"
			:options.sync="options"
			:server-items-length="pagination.totalItems"
			class="elevation-1"
			single-expand
			:expanded.sync="expanded"
			show-expand
			:footer-props="{
				'items-per-page-options': [5, 10, 15, 25, 50, 100]
			}"
			group-by="imtid"
        >
			<template v-slot:group.header="{ group, items, headers, toggle, isOpen }">
				<td :colspan="2">
					<v-btn @click="toggle" small icon :ref="group" :data-open="isOpen">
						<v-icon v-if="isOpen">mdi-chevron-up</v-icon>
						<v-icon v-else>mdi-chevron-down</v-icon>
					</v-btn>
					{{ group }}
				</td>
				<td :colspan="4">
					{{ items[0].root_name }}
				</td>
				<td :colspan="3">
					Заказы 7 дней: {{ sum_root_product_orders_7(items) | toRUB}}
				</td>
				<td :colspan="3">
					Заказы 30 дней: {{ sum_root_product_orders_30(items) | toRUB}}
				</td>
				<td :colspan="headers.length - 8"></td>
			</template>
			<template v-slot:header.rentab_value="{ header }">
				{{ header.text }}
				<v-tooltip bottom max-width=1200>
					<template v-slot:activator="{ on, attrs }">
						<span v-bind="attrs" v-on="on" style="cursor: pointer">
							<v-icon x-small class="ml-2">mdi-help-circle-outline</v-icon>
						</span>
					</template>
					<span>
						<p style="white-space: nowrap;" v-for="i in calcHelpText('rentab_value')" :key="i">{{ i }}</p>
					</span>
				</v-tooltip>
			</template>
			<template v-slot:header.rentab_mp="{ header }">
				{{ header.text }}
				<v-tooltip bottom max-width=1200>
					<template v-slot:activator="{ on, attrs }">
						<span v-bind="attrs" v-on="on" style="cursor: pointer">
							<v-icon x-small class="ml-2">mdi-help-circle-outline</v-icon>
						</span>
					</template>
					<span>
						<p style="white-space: nowrap;" v-for="i in calcHelpText('rentab_mp')" :key="i">{{ i }}</p>
					</span>
				</v-tooltip>
			</template>
            <template v-slot:item.pic="{ item }">
                <img style="max-height:100px" :src="item.image" alt="">
            </template>
            <template v-slot:item.nmid="{ item }">
                <a :href="`https://www.wildberries.ru/catalog/${item.catalog_item.sku}/detail.aspx`" target="_blank">Смотреть на wb</a> |
                <router-link target="_blank" :to="{ name: 'product_card', params: { id: item.catalog_item.sku }}">{{ item.catalog_item.sku }}</router-link> |<br>
				<div>{{item.catalog_item.code1C_code}}</div>
                <v-icon @click="copyArt(item.catalog_item.sku)" class="mb-3" small>
                    mdi-content-copy
                </v-icon>
				<v-icon small class="ml-1 mb-2" title="Добавление новых ключей" @click="editItemMonitoring(item)">
					mdi-plus
				</v-icon>
				<v-icon small class="ml-1 mb-2" title="Добавление в избранное" @click="toggleBookmarks(item)" v-if="productInBookmarks(item)">
					mdi-star-plus-outline
				</v-icon>
				<v-icon small class="ml-1 mb-2" title="Добавление в избранное" @click="toggleBookmarks(item)" v-else>
					mdi-star-plus
				</v-icon>
            </template>
			<template v-slot:item.plan_execution_vp_mp="{ item }">
                <div class="small-text">
					{{ item.catalog_item.code1C_name }}
					<router-link 
						target="_blank" 
						:to="{ name: 'product_char', params: { id: item.nmid }}"
					>
						<v-icon style="font-size: 1rem;">mdi-pencil</v-icon>
					</router-link>
					<div class="small-text" :class="{'green_rentab': item.dynamic_fields.plan_execution_vp_mp >= 100 && item.dynamic_fields.plan_vp_mp != 0, 'orange_rentab': item.dynamic_fields.plan_execution_vp_mp >= 90 && item.dynamic_fields.plan_execution_vp_mp < 100 && item.dynamic_fields.plan_vp_mp != 0, 'red_rentab2': item.dynamic_fields.plan_execution_vp_mp < 90 && item.dynamic_fields.plan_vp_mp != 0}">
						<p class="mb-0 pb-0" style="line-height: 1.2; white-space: nowrap;">План: {{ item.dynamic_fields.plan_vp_mp | toRUBWithoutKopecks }}</p>
						<p class="mb-0 pb-0" style="line-height: 1.2; white-space: nowrap;">Прогноз: {{ item.dynamic_fields.progress_vp_mp | toRUBWithoutKopecks }}</p>
						<p class="mb-0 pb-0" style="line-height: 1.2; white-space: nowrap; font-weight: bold">Выполнение: {{ item.dynamic_fields.plan_execution_vp_mp.toFixed(0) }}%</p>
					</div>
				</div>
            </template>
            <template v-slot:item.brand="{ item }">
                <div class="pt-2 small-text">{{ item.catalog_item.brand ? item.catalog_item.brand : "-" }} |</div>
                <div class="pt-2 small-text">{{ item.catalog_item.code1C_tag_group_name ? item.catalog_item.code1C_tag_group_name : "-"}} |</div>
				<div class="pt-2 small-text">{{ item.object ? item.object : "-"}}</div>
            </template>
            <template v-slot:item.status="{ item }">
				<div 
					class="small-text pt-2" style="white-space: nowrap;" 
					:class="{'orange_value': item.dynamic_fields.abc_class == 'B', 'green_value': item.dynamic_fields.abc_class == 'A', 'red_value': item.dynamic_fields.abc_class == 'C' || item.dynamic_fields.abc_class == 'D'}"
				>ABC: {{ item.dynamic_fields.abc_class }}</div>
                <div class="pt-2 small-text">
					{{ item.catalog_item.status_name ? item.catalog_item.status_name : "-" }} |
				</div>
                <div class="pt-2 small-text">
					{{ item.catalog_item.account_name ? item.catalog_item.account_name : "-" }}
				</div>
				<div class="mt-2 small-text orange_rentab" style="white-space: nowrap;" v-if="item.dynamic_fields.days_from_first_stocks <= 91 || item.catalog_item.status_name == 'Новинка' ">
					{{daysLabel(item.dynamic_fields.days_from_first_stocks)}}
				</div>
            </template>
			<template v-slot:item.last_price_wb="{ item }">
				<div class="d-flex align-center justify-center">
					{{ item.dynamic_fields.last_price_without_spp ? item.dynamic_fields.last_price_without_spp : 0 | toRUB }}
					({{ item.dynamic_fields.last_price_with_spp ? item.dynamic_fields.last_price_with_spp : 0 | toRUB }})
					<span v-if="!item.on_sale">⛔️</span>
					<v-tooltip bottom max-width=350 v-if="item.dynamic_fields && item.dynamic_fields.promotions.length > 0">
						<template v-slot:activator="{ on, attrs }">
							<div v-bind="attrs" v-on="on" class="ml-1">
								<a href="#" class="dashed small-text">(А)</a>
							</div>
						</template>
						<span>
							<p v-for="(el, index) in item.dynamic_fields.promotions" :key="index">{{ el }}</p>
						</span>
					</v-tooltip>
				</div>
				<div class="text-center">
					С/С: {{ item.dynamic_fields.cost_price ? item.dynamic_fields.cost_price : 0 | toRUB }}
				</div>
				<div 
					style="max-width: 150px; width: 150px" 
					v-if="item.dynamic_fields && (item.dynamic_fields.orders_array || item.dynamic_fields.price_array)"
				>
					<OrdersChartProductList 
						:series="getOrdersGraph(item, 1, 15)"
						:series_price="getPriceGraph(item, 1, 15)"
					/>
				</div>
            </template>
			<template v-slot:item.last_stock_wb="{ item }">
                <div class="small-text" style="white-space: nowrap;">
					{{ item.dynamic_fields && item.dynamic_fields.last_stock_wb ? item.dynamic_fields.last_stock_wb : 0 }}
					(fbs: {{ item.dynamic_fields && item.dynamic_fields.stocks_fbs ? item.dynamic_fields.stocks_fbs : 0 }})
					<hr>
					парсинг: {{ item.dynamic_fields.parser_stock ? item.dynamic_fields.parser_stock : 0 }}
				</div>
				<v-tooltip bottom max-width="300" v-if="item.is_fbs_logistic">
					<template v-slot:activator="{ on, attrs }">
						<v-chip class="mx-1 px-1" color="red" outlined x-small v-bind="attrs" v-on="on">
							FBS
						</v-chip>
					</template>
						<span>Логистика и хранение FBS</span>
				</v-tooltip>
            </template>
            <template v-slot:item.last_stock_1c="{ item }">
				<div class="small-text">
					{{ item.dynamic_fields && item.dynamic_fields.last_stock_1c ? item.dynamic_fields.last_stock_1c: 0 }}
				</div>
            </template>
			<template v-slot:item.orders_api_30days="{ item }">
				<div class="small-text" v-if="item.dynamic_fields">
					<div>{{item.dynamic_fields.orders_30days ? item.dynamic_fields.orders_30days : 0 | toRUB }}</div>
					<div>{{item.dynamic_fields.orders_count_30days ? item.dynamic_fields.orders_count_30days : 0 }} шт</div>
					<div
						:class="{
							'green_rentab': calculateDelta(
								item.dynamic_fields.orders_prev_days.days_30, item.dynamic_fields.orders_30days
							) > 0, 
							'red_rentab': calculateDelta(
								item.dynamic_fields.orders_prev_days.days_30, item.dynamic_fields.orders_30days
							) < 0
						}"
					>
						({{ calculateDelta(
							item.dynamic_fields.orders_prev_days.days_30, item.dynamic_fields.orders_30days
						) }}% / 
						{{ calculateDelta(
							item.dynamic_fields.orders_count_prev_days.days_30, item.dynamic_fields.orders_count_30days
						) }}%) 
					</div>
				</div>
            </template>
			<template v-slot:item.drr="{ item }">
				<div class="small-text" style="display:flex; flex-direction: column; align-items: center;" v-if="item.dynamic_fields">
					<v-tooltip bottom max-width=350>
						<template v-slot:activator="{ on, attrs }">
							<div v-bind="attrs" v-on="on" style="white-space: nowrap;">
								ДРР: {{ item.dynamic_fields.drr_14 ? item.dynamic_fields.drr_14 : 0 }}% / {{ item.dynamic_fields.drr_7 ? item.dynamic_fields.drr_7 : 0 }}%
								<br>
								Расход: {{ item.dynamic_fields.drr_expenses_14 ? item.dynamic_fields.drr_expenses_14 : 0 | toRUB}} / {{ item.dynamic_fields.drr_expenses_7 ? item.dynamic_fields.drr_expenses_7 : 0 | toRUB}}
							</div>
						</template>
						<span>
							<div class="small-text">ДРР за 7 дней: {{ item.dynamic_fields.drr_14 ? item.dynamic_fields.drr_7 : 0 }}%</div>
							<div class="small-text">ДРР за 14 дней: {{ item.dynamic_fields.drr_7 ? item.dynamic_fields.drr_14 : 0 }}%</div>
							<div class="small-text">Расход за 7 дней: {{ item.dynamic_fields.drr_expenses_7 ? item.dynamic_fields.drr_expenses_7 : 0 | toRUB}}</div>
							<div class="small-text">Расход за 14 дней: {{ item.dynamic_fields.drr_expenses_14 ? item.dynamic_fields.drr_expenses_14 : 0 | toRUB}}</div>
							<div class="small-text">Расход включает в себя внутреннюю рекламу, раздачи, блогеров. ДРР рассчитывается из суммы заказов ЛК с учетом комиссии</div>
						</span>
					</v-tooltip>
					<div style="max-width: 150px; width: 150px" v-if="item.dynamic_fields.drr_expenses_14 > 0">
						<OrdersChartProductList 
							:series="getDrrExpensesGraph(item, 1, 15)"
							:series_price="getDrrGraph(item, 1, 15)"
							:is_drr="true"
						/>
					</div>
				</div>
            </template>
			<template v-slot:item.orders_api_7days="{ item }">
				<div class="small-text" v-if="item.dynamic_fields">
					<div>{{item.dynamic_fields.orders_7days ? item.dynamic_fields.orders_7days : 0 | toRUB }}</div>
					<div>{{item.dynamic_fields.orders_count_7days ? item.dynamic_fields.orders_count_7days : 0 }} шт</div>
					<div
						:class="{
							'green_rentab': calculateDelta(
								item.dynamic_fields.orders_prev_days.days_7, item.dynamic_fields.orders_7days
							) > 0, 
							'red_rentab': calculateDelta(
								item.dynamic_fields.orders_prev_days.days_7, item.dynamic_fields.orders_7days
							) < 0
						}"
					>
						({{ calculateDelta(
							item.dynamic_fields.orders_prev_days.days_7, item.dynamic_fields.orders_7days
						) }}% / 
						{{ calculateDelta(
							item.dynamic_fields.orders_count_prev_days.days_7, item.dynamic_fields.orders_count_7days
						) }}%) 
					</div>
				</div>
            </template>
			<template v-slot:item.sales_30days="{ item }">
				<div class="small-text" v-if="item.dynamic_fields">
					<div>{{item.dynamic_fields.sales_30days ? item.dynamic_fields.sales_30days : 0 | toRUB }}</div>
					<div>{{item.dynamic_fields.sales_count_30days ? item.dynamic_fields.sales_count_30days : 0 }} шт</div>
					<div
						:class="{
							'green_rentab': calculateDelta(
								item.dynamic_fields.sales_prev_days.days_30, item.dynamic_fields.sales_30days
							) > 0, 
							'red_rentab': calculateDelta(
								item.dynamic_fields.sales_prev_days.days_30, item.dynamic_fields.sales_30days
							) < 0
						}"
					>
						({{ calculateDelta(
							item.dynamic_fields.sales_prev_days.days_30, item.dynamic_fields.sales_30days
						) }}% / 
						{{ calculateDelta(
							item.dynamic_fields.sales_count_prev_days.days_30, item.dynamic_fields.sales_count_30days
						) }}%) 
					</div>
				</div>
            </template>
			<template v-slot:item.speed_orders="{ item }">
				<div class="small-text" v-if="item.dynamic_fields && (item.dynamic_fields.speed_orders || item.dynamic_fields.speed_orders_7)">
					{{ item.dynamic_fields.speed_orders ? item.dynamic_fields.speed_orders.toFixed(2) : 0 }}
					({{ item.dynamic_fields.speed_orders_7 ? item.dynamic_fields.speed_orders_7.toFixed(2): 0 }})
				</div>
				<div class="small-text" v-else>-</div>
            </template>
			<template v-slot:item.day_stock="{ item }">
				<div class="small-text" v-if="item.dynamic_fields">
					{{ item.dynamic_fields.day_stock ? item.dynamic_fields.day_stock.toFixed(2) : 0 }}
					({{ item.dynamic_fields.day_stock_1c ? item.dynamic_fields.day_stock_1c.toFixed(2) : 0 }})
				</div>
				<div class="small-text" v-else>-</div>
            </template>
			<template v-slot:item.index_day="{ item }">
                <div class="small-text" style="max-width: 150px; width: 150px">
					<OrdersChartProductList 
						:series="getVisibilityGraph(item, 1, 15)" :is_visibility="true"
					/>
				</div>
            </template>
			<template v-slot:item.rentab_mp="{ item }">
				<div 
					style="white-space: nowrap;" 
					:class="{
						'green_rentab': item.dynamic_fields.rentab_mp > 18, 
						'orange_rentab': item.dynamic_fields.rentab_mp > 8 && item.dynamic_fields.rentab_mp <= 18, 
						'red_rentab': item.dynamic_fields.rentab_mp <= 8
					}">
					{{ item.dynamic_fields.rentab_mp ? item.dynamic_fields.rentab_mp : 0 }}% ({{ item.dynamic_fields.vp_mp ? item.dynamic_fields.vp_mp : 0 | toRUB }})
				</div>
				<div style="max-width: 150px; width: 150px" v-if="item.dynamic_fields.vp_mp_array || item.dynamic_fields.rentab_mp_array">
					<OrdersChartProductList 
						:series="getVpMpGraph(item, 1, 15)"
						:series_price="getRentabMpGraph(item, 1, 15)"
						:is_vp="true"
					/>
				</div>
            </template>        
			<template v-slot:item.delta_rentab="{ item }">
				<div class="d-flex">
					<v-tooltip bottom max-width=350>
						<template v-slot:activator="{ on, attrs }">
							<div v-bind="attrs" v-on="on" >
								<div class="small-text" style="white-space: nowrap;">
									Прогноз: {{ item.dynamic_fields ? item.dynamic_fields.rentab_ls : 0 }}% ({{ item.dynamic_fields ? item.dynamic_fields.vp_ls : 0 | toRUB }})
								</div>
								<hr>
								<div class="small-text" style="white-space: nowrap;">
									Факт: {{ item.dynamic_fields ? item.dynamic_fields.rentab_sales_ls : 0 }}% ({{ item.dynamic_fields ? item.dynamic_fields.vp_sales_ls : 0 | toRUB }})
								</div>
								<div class="small-text" style="white-space: nowrap;" :class="{'red_rentab': item.delta_rentab > 5}">
									delta: {{ item.delta_rentab ? item.delta_rentab : 0 }}%
								</div>
							</div>
						</template>
						<span>
							<div class="small-text">ВП_бс_н прогноз: {{ item.dynamic_fields ? item.dynamic_fields.rentab_plan : 0 }}% ({{ item.dynamic_fields ? item.dynamic_fields.vp_unit_plan : 0 | toRUB }})</div>
							<div class="small-text">ВП_ЛС прогноз: {{ item.dynamic_fields ? item.dynamic_fields.rentab_ls : 0 }}% ({{ item.dynamic_fields ? item.dynamic_fields.vp_ls : 0 | toRUB }})</div>
							<div class="small-text">ВП_М прогноз: {{ item.dynamic_fields ? item.dynamic_fields.retab_m : 0 }}% ({{ item.dynamic_fields ? item.dynamic_fields.vp_m : 0 | toRUB }})</div>
							<div class="small-text">ВП_МП прогноз: {{ item.dynamic_fields ? item.dynamic_fields.rentab_mp : 0 }}% ({{ item.dynamic_fields ? item.dynamic_fields.vp_mp : 0 | toRUB }})</div>
						</span>
					</v-tooltip>
					<v-tooltip bottom max-width=350 v-if="item.dynamic_fields.vp_unit_plan == item.dynamic_fields.vp_ls">
						<template v-slot:activator="{ on, attrs }">
							<div v-bind="attrs" v-on="on">
								<v-icon color="red">mdi-exclamation</v-icon>
							</div>
						</template>
						<span>Расходы не загружены</span>
					</v-tooltip>    
				</div>           
            </template> 
            <template v-slot:expanded-item="{ headers, item }">
				<td :colspan="headers.length">
					<div class="pb-5">
						<KeywordTable :product_id="item.nmid" :key="item.id" favorite="true"/>
					</div>
					
				</td>
            </template>
            <template v-slot:top>
                <div class="d-flex w-100 px-7 pt-5">
					<v-btn
						color="primary" dark class="mb-2" @click="exportToExcel" :loading="loading_export"
					>Экспорт</v-btn>
					<v-btn
						color="primary" dark class="mb-2 ml-5" @click="exportWithoutPlan" :loading="loading_export_without_plan"
					>Экспорт без планов</v-btn>	
					<v-btn
						color="primary" dark class="mb-2 ml-5" @click="openDiagCharts()"
					>Графики</v-btn>
					<v-btn
						color="primary" dark class="mb-2 ml-5" @click="dialog_stats_excel=true"
					>Загрузка показателей</v-btn>
					<v-btn
						color="primary" dark class="mb-5 ml-5" @click="update_stats_excel.dialog=true"
						:loading="update_stats_excel.loading"
					>Обновление показателей</v-btn>
					<router-link target="_blank" :to="{ name: 'product_indices'}">
						<v-btn color="primary" dark class="mb-2 ml-5">Индексы</v-btn>		
					</router-link>	
					<router-link target="_blank" :to="{ name: 'wb_product_promotions'}">
						<v-btn color="primary" dark class="mb-2 ml-5">Акции</v-btn>		
					</router-link>	
					<v-btn
						color="primary" dark class="mb-5 ml-5" @click="exportPriceCompetitors"
						:loading="loading_export_price_competitors"
					>Экспорт цен конкурентов</v-btn>
					<v-btn
						color="primary" dark class="mb-5 ml-5" @click="changeRootDisplay"
					>Группировка по цветам({{ is_root_display ? 'on': 'off' }})</v-btn>
              </div>
            </template>
        </v-data-table>
    </div>
</template>

<script>
	import { KeywordMonitoring } from '@/api/keyword_monitoring'
	import { Products } from '@/api/products'
	import { General } from '@/api/general'
	import { ManagementProduct } from '@/api/management_product'
	import KeywordTable from '@/components/Product/KeywordTable'
	import OrdersChartProductList from '@/components/Product/OrdersChartProductList'
	import ChartsProductData from '@/components/Product/ChartsProductData'
	export default {
		name: 'ProductsList',
		components: {
			KeywordTable,
			OrdersChartProductList,
			ChartsProductData
		},
		data () {
			return {
				loading_export_without_plan: false,
				is_root_display: false,
				loading_export_price_competitors: false,
				list_bookmarks: [],
				datepicker_date: null,
				first_init: false,
				update_stats_excel:{
					date_from : null,
					dialog: false,
					loading: false,
					flag_one_day: false
				},
				loading_upload: false,
				dialog_stats_excel: false,
				loading_export: false,
				dialog_monitoring: false,
				dialog_charts: false,
				expanded: [],
				key_list_monitoring:null,
				current_item_monitoring: null,
				edited_index_monitoring: -1,
				tableLoading: false,
				pagination: {
					next: null,
					totalItems: 0,
					numberOfPages: 0,
					currentPage: 0
				},
				options: {
					sortBy: ['orders_api_30days'],
					sortDesc: [true],
					groupBy: this.is_root_display ? ['imtid']: [],
					itemsPerPage: 25
				},
				drawer: false,
				filters: {
					stock_day: 0,
					rentab: 0,
					stock_fbs: 0,
					product_rating: [],
					profile: 0,
					stock: 0,
					product_rating_range: [0,5],
					name: null,
					brand: null,
					group: null,
					date_before: null,
					date_after: null,
					nmid: null,
					code_1c:null,
					advertising: null,
					status: ['0001', '0008'],
					abc_status: null,
					account: null,
					cost_price: 0,
					manager: null,
					tag: null,
					is_bookmark: null,
					is_empty_expenses: false,
					logistic: 0,
					object: null
				},
				filter_choices: {
					wbaccount:[],
					product_status_choices: [],
					product_manager_choices: [],
					product_brand_choices: [],
					product_group_choices: [],
					product_profile_choices: [],
				},
				items: [],
				dataTable: {
					headers: [
						{ text: '', value: 'data-table-expand', width: '1%' },
						{ text: 'Картинка', align: 'start', sortable: false, value: 'pic', width: '1%'},
						{ text: 'id', align: 'start', sortable: true, value: 'nmid', width: '1%' },
						{ text: 'Название', align: 'start', sortable: true, value: 'plan_execution_vp_mp', width: '1%' },
						{ text: 'Бренд / Группа / Предмет', align: 'start', sortable: false, value: 'brand', width: '1%' },
						{ text: 'Статус / ЛК', value: 'status', width: '1%', sortable: false },
						{ text: 'Цена продажи (с СПП) / С/С', value: 'last_price_wb', width: '1%', sortable: true },
						{ text: 'ВП_ЛС прогноз', value: 'delta_rentab', width: '2%', sortable: true },
						{ text: 'ВП_МП прогноз', value: 'rentab_mp', width: '2%', sortable: false },
						{ text: 'ДРР / расход(14 / 7 дней) вн. реклама', value: 'drr', width: '1%', sortable: true },
						{ text: 'Остаток ЛК (сегодня)', value: 'last_stock_wb', width: '1%', sortable: true },
						{ text: 'Остаток 1C (комплекты)', value: 'last_stock_1c', width: '1%', sortable: true},
						{ text: 'Заказы(ЛК) за 7 дней', value: 'orders_api_7days', width: '1%', sortable: true },
						{ text: 'Заказы(ЛК) за 30 дней', value: 'orders_api_30days', width: '1%', sortable: true },
						{ text: 'Продажи ЛК за 30 дней', value: 'sales_30days', width: '1%', sortable: true},
						{ text: 'Скорость заказов, 14 дней (7 дней)', value: 'speed_orders', width: '1%', sortable: true},
						{ text: 'Дней остатка(с 1С)', align: 'start', sortable: true, value: 'day_stock', width: '1%'},
						{ text: 'Видимость', value: 'index_day', width: '1%', sortable: true },		
					],
					headers_with_group: [
						{ text: '', value: 'data-table-expand', width: '1%' },
						{ text: 'Картинка', align: 'start', sortable: false, value: 'pic', width: '1%'},
						{ text: 'id', align: 'start', sortable: false, value: 'nmid', width: '1%' },
						{ text: 'Название', align: 'start', sortable: false, value: 'plan_execution_vp_mp', width: '1%' },
						{ text: 'Бренд / Группа / Предмет', align: 'start', sortable: false, value: 'brand', width: '1%' },
						{ text: 'Статус / ЛК', value: 'status', width: '1%', sortable: false },
						{ text: 'Цена продажи (с СПП) / С/С', value: 'last_price_wb', width: '1%', sortable: false },
						{ text: 'ВП_ЛС прогноз', value: 'delta_rentab', width: '2%', sortable: false },
						{ text: 'ВП_МП прогноз', value: 'rentab_mp', width: '2%', sortable: false },
						{ text: 'ДРР / расход(14 / 7 дней) вн. реклама', value: 'drr', width: '1%', sortable: false },
						{ text: 'Остаток ЛК (сегодня)', value: 'last_stock_wb', width: '1%', sortable: false },
						{ text: 'Остаток 1C (комплекты)', value: 'last_stock_1c', width: '1%', sortable: false},
						{ text: 'Заказы(ЛК) за 7 дней', value: 'orders_api_7days', width: '1%', sortable: true },
						{ text: 'Заказы(ЛК) за 30 дней', value: 'orders_api_30days', width: '1%', sortable: true },
						{ text: 'Продажи ЛК за 30 дней', value: 'sales_30days', width: '1%', sortable: false},
						{ text: 'Скорость заказов, 14 дней (7 дней)', value: 'speed_orders', width: '1%', sortable: false},
						{ text: 'Дней остатка(с 1С)', align: 'start', sortable: false, value: 'day_stock', width: '1%'},
						{ text: 'Видимость', value: 'index_day', width: '1%', sortable: false },		
					],
				},
				tableItemLoader: []
			}
		},
		watch: {
			'options': async function (newVal, oldVal) {
				if (oldVal && this.first_init) {
					await this.fetchProducts()
				}
			},
		},
		computed: {
			dateTransform () {
				return this.update_stats_excel.date_from ? this.$options.filters.formatDateToLocaleNoTime(this.update_stats_excel.date_from) : null
			},
			transfromFiltersForQuery () {
				let query = {}
				for (const f in this.filters) {
					if (Array.isArray(this.filters[f])) {
						query[f] = this.filters[f].join(',')
					} else if(f=='nmid'){
						query[f] = this.filters[f] ? this.filters[f].replace(/\s/g,'') : this.filters[f]
					}else {
						query[f] = this.filters[f]
					}
					if (this.filters[f] == false) {
						query[f] = null
					}
				}
				return query
			},
		},
		methods: {
			async changeRootDisplay(){
				this.is_root_display = !this.is_root_display
				if (this.is_root_display){
					localStorage.setItem('product_list_by_group', true)
					this.options.groupBy = ['imtid']
					this.options.itemsPerPage = 5
				} else {
					localStorage.removeItem('product_list_by_group')
					this.options.groupBy = []
					this.options.itemsPerPage = 25
				}
				await this.fetchProducts()
			},
			calcHelpText(label){
				if (label == 'rentab_value')
					return [
						'Прогноз = прогнозное значение на сегодня',
						'Факт = фактическое значение вп_лс по данным из последнего фин. отчета',
						'ВП прогноз = цена продажи - с/с - логистика - комиссия - затраты на возвраты - налог - эквайринг',
						'ВП_ЛС прогноз = ВП прогноз - плановые расходы склада и логистики до МП',
						'ВП_М прогноз = ВП_ЛС прогноз - средние расходы на рекламу за 14 дней',
						'ВП_МП прогноз = ВП_М прогноз - плановые затраты МП',
						'ВП_ЛС факт = ВП факт - плановые расходы склада и логистики до МП'
					]
                if (label == 'rentab_mp')
					return [
						'ВП_МП прогноз = ВП_М прогноз - плановые затраты МП',
					]
				return null
			},
			async downloadTempate(is_one){
				try {
					let json_data = {}
					if (is_one)
						json_data['is_one'] = true
					const response = await Products.downloadTemplateMpstatsStatas(json_data)
					const url = URL.createObjectURL(response.data)
					const link = document.createElement('a')
					link.href = url
					link.setAttribute('download', 'template.xlsx')
					document.body.appendChild(link)
					link.click()
					document.body.removeChild(link)
				} catch (err) {
					console.log(err.data)
				}
			},
			async uploadUpdatedStatsFromExcel(){
				let fileToUpload = document.getElementById('upload_update_stats_product').files[0]
				let formData = new FormData()
				formData.append('fileToUpload', fileToUpload)
				formData.append('date_from', this.update_stats_excel.date_from)
				formData.append(
					'flag_one_day', 
					!this.update_stats_excel.flag_one_day ? this.update_stats_excel.flag_one_day : 1
				)
				try {
					this.update_stats_excel.loading = true
					this.update_stats_excel.dialog = false
					const response = await Products.uploadUpdateStatsFromExcel(formData)
					this.update_stats_excel.loading = false
				}
				catch (err){
					console.log(err)
				}
				this.update_stats_excel.loading = false
			},
			async uploadFromExcel(){
				let fileToUpload = document.getElementById('upload_input_stats_product').files[0]
				let formData = new FormData()
				formData.append('fileToUpload', fileToUpload)

				try {
					this.loading_upload = false
					this.dialog_stats_excel = false
					const response = await Products.uploadStatsFromExcel(
						formData
					)
					this.loading_upload = false
				}
				catch (err){
					console.log(err)
				}
				this.loading_upload = false
			},
			async openDiagCharts(){
				this.dialog_charts = true
				if (this.$refs.chart_products){
					this.$refs.chart_products.getData()
				}
			},
			calculateDelta(old_val, new_val){
				if (!old_val || old_val == 0 || !new_val || new_val == 0)
					return 0
				return ((new_val - old_val) / old_val * 10000 / 100).toFixed(2)
			},
			getOrdersGraph(item, day_start, day_end){
				let orders_dict = item.dynamic_fields.orders_array
				return orders_dict.slice(day_start, day_end).reverse()
			},
			getVisibilityGraph(item, day_start, day_end){
				let orders_dict = item.dynamic_fields.visibility_array
				return orders_dict.slice(day_start, day_end).reverse()
			},
			getPriceGraph(item, day_start, day_end){
				let orders_dict = item.dynamic_fields.price_array
				return orders_dict.slice(day_start, day_end).reverse()
			},
			getDrrExpensesGraph(item, day_start, day_end){
				let orders_dict = item.dynamic_fields.drr_expenses_array
				return orders_dict.slice(day_start, day_end).reverse()
			},
			getDrrGraph(item, day_start, day_end){
				let orders_dict = item.dynamic_fields.drr_array
				return orders_dict.slice(day_start, day_end).reverse()
			},
			getVpMpGraph(item, day_start, day_end){
				let orders_dict = item.dynamic_fields.vp_mp_array
				return orders_dict.slice(day_start, day_end).reverse()
			},
			getRentabMpGraph(item, day_start, day_end){
				let orders_dict = item.dynamic_fields.rentab_mp_array
				return orders_dict.slice(day_start, day_end).reverse()
			},
			async exportToExcel(){
				this.loading_export = true
				try {
					const response = await Products.exportProducts({
						...this.transfromFiltersForQuery
					})
					const url = URL.createObjectURL(response.data)
					const link = document.createElement('a')
					link.href = url
					link.setAttribute('download', 'products_list.xlsx')
					document.body.appendChild(link)
					link.click()
					document.body.removeChild(link)
				} catch (err) {
					console.log(err.data)
				}
				this.loading_export = false
			},
			copyArt(art) {
				const el = document.createElement('textarea')
				el.value = art
				el.setAttribute('readonly', '')
				el.style.position = 'absolute'
				el.style.left = '-99999px'
				document.body.appendChild(el)
				el.select()
				document.execCommand('copy')
				document.body.removeChild(el)
				this.$toasted.show(`Скопирован в буфер`, {
					theme: "bubble",
					type: 'success',
					position: "top-center",
					duration : 500
				})
			},
			async saveKeyword () {
				try {
					const response = await Products.setKeywordMonitoring({
						"product_id": this.current_item_monitoring.id, 
						"text": this.key_list_monitoring
					})
					await this.fetchProducts()
					if (response.status == 204) {
						this.$toasted.show(`Ключевые запросы для товара ${this.current_item_monitoring.nmid} изменены"`, {
							theme: "bubble",
							type: 'success',
							position: "top-center",
							duration : 3000
						})
					}
				}
				catch (err){
					console.log(err)
				}
				this.key_list_monitoring = null
				this.dialog_monitoring = false
			},
			editItemMonitoring (item) {
				this.edited_index_monitoring = this.items.indexOf(item)
				this.current_item_monitoring = item
				this.dialog_monitoring = true
			},
			async closeWindowMonitoring () {
				this.dialog_monitoring = false
				this.key_list_monitoring = null
			},
			async clearFilters () {
				let dirtyFilters = false
				for (let item in this.filters) {
					if (this.filters[item]) {
						dirtyFilters = true
					}
					this.filters[item] = Array.isArray(this.filters[item]) ? [] : null
				}
				if (dirtyFilters) {
					await this.fetchProducts()
				}
			},
			async fetchWbAccounts () {
				const response = await General.getWbAccounts()
				for (let account in response.data) {
					this.filter_choices.wbaccount.push(response.data[account])
				}
			},
			async fetchFeedbacksWithFilters () {
				this.drawer = false
				this.options.page = 1
				await this.fetchProducts()
			},
			async fetchProducts () {
				this.tableLoading = true
				this.$vuetify.goTo(this.$refs.product_table_filters, {
						duration: 500,
						offset: 150,
						easing: 'linear',
					})
				const { page, itemsPerPage, sortBy, sortDesc } = this.options
				let pageNumber = page - 1
				try {
					let query_data = {
						...this.transfromFiltersForQuery,
						'offset': itemsPerPage*pageNumber,
						'limit': itemsPerPage,
						'sort': sortDesc[0] ? `-${sortBy[0]}` : sortBy[0]
					}
					let response
					if (this.is_root_display){
						response = await Products.fetchRootProductsList(query_data)
					} else {
						response = await Products.fetchProducts(query_data)
					}
					this.items = response.data.results.objects
					this.filter_choices.product_brand_choices = response.data.results.filters.brand
					this.filter_choices.product_status_choices = response.data.results.filters.status
					this.filter_choices.product_group_choices = response.data.results.filters.group
					this.pagination.totalItems = response.data.count
					this.pagination.numberOfPages = Math.ceil(response.data.count / itemsPerPage)
				} catch (err) {
					console.log(err.data)
				}
				this.tableLoading = false
			},
			async fetchProductGroup() {
				try {
					const response = await General.fetchProductGroup()
					this.filter_choices.product_group_choices = response.data
				} catch (err) {
					console.log(err.data)
				}
			},
			async fetchProductStatus() {
				try {
					const response = await General.fetchProductStatus()
					this.filter_choices.product_status_choices = response.data
				} catch (err) {
					console.log(err.data)
				}
			},
			async fetchProductManager() {
				try {
					const response = await General.fetchProductManager()
					this.filter_choices.product_manager_choices = response.data
				} catch (err) {
					console.log(err.data)
				}
			},
			async fetchProductProfile() {
				try {
					const response = await General.fetchProductProfile()
					this.filter_choices.product_profile_choices = response.data
				} catch (err) {
					console.log(err.data)
				}
			},
			productInBookmarks(item){
				if (this.list_bookmarks.includes(item.nmid)){
					return false
				}
				return true
			},
			async toggleBookmarks(item){
				try{
					await Products.toggleWbBookmarks(item.nmid)
					await this.fetchBookmarks()
					this.$toasted.show(`Товар добавлен в избранное`, {
						theme: "bubble",
						type: 'success',
						position: "top-center",
						duration : 3000
					})
				} catch(err){
					console.log(err)
					this.$toasted.show(`Ошибка`, {
						theme: "bubble",
						type: 'error',
						position: "top-center",
						duration : 3000
					})
				}
			},
			async fetchBookmarks(){
				try{
					const response = await Products.fetchWbBookmarks()
					this.list_bookmarks = response.data
				} catch(err){
					console.log(err)
				}
			},
			sum_root_product_orders_7(items){
				let result = 0
				items.forEach(item => {
					result += item.dynamic_fields.orders_7days
				})
				return result
			},
			sum_root_product_orders_30(items){
				let result = 0
				items.forEach(item => {
					result += item.dynamic_fields.orders_30days
				})
				return result
			},
			async exportPriceCompetitors(){
				this.loading_export_price_competitors = true
				try {
					const response = await Products.exportWBPriceCompetitors(this.transfromFiltersForQuery)
					const url = URL.createObjectURL(response.data)
					const link = document.createElement('a')
					link.href = url
					link.setAttribute('download', 'Цены_и_скидки_конкурентов_ВБ.xlsx')
					document.body.appendChild(link)
					link.click()
					document.body.removeChild(link)
				} catch (err) {
					console.log(err.data)
				}
				this.loading_export_price_competitors = false
			},
			daysLabel(number) {
				let titles = ['день', 'дня', 'дней']
				const cases = [2, 0, 1, 1, 1, 2];
				return `Новинка ${number} ${titles[number % 100 > 4 && number % 100 < 20 ? 2 : cases[number % 10 < 5 ? number % 10 : 5]]}`;
			},
			async exportWithoutPlan(){
				this.loading_export_without_plan = true
				try {
					const response = await Products.exportWBWithoutPlans({
						...this.transfromFiltersForQuery
					})
					const url = URL.createObjectURL(response.data)
					const link = document.createElement('a')
					link.href = url
					link.setAttribute('download', 'wb_products_list.xlsx')
					document.body.appendChild(link)
					link.click()
					document.body.removeChild(link)
				} catch (err) {
					console.log(err.data)
				}
				this.loading_export_without_plan = false
			},
		},
		async mounted () {
			if (localStorage.getItem("product_list_by_group")){
				this.is_root_display = true
				this.options.groupBy = ['imtid']
				this.options.itemsPerPage = 5
			}
			await this.fetchBookmarks()
			await this.fetchProducts()
			this.first_init = true
			this.fetchWbAccounts()
			this.fetchProductManager()
			this.fetchProductProfile()
		}
	}
</script>

<style scoped lang="scss">
    ::v-deep .v-rating .v-icon{
        padding: 0 !important;
    }
    .rating-chip{
        display: inline-block;
        padding: 3px 8px;
        color: #ffffff;
        border-radius: 5px;
        font-size: .9rem;
    }
    .text-danger{
      color: red;
    }
	.green_rentab{
		background-color: #c0f1b4;
	}
	.orange_rentab{
		background-color: #f1f1d2;
	}
	.red_rentab{
		background-color: #f1b7bd;
	}
	.theme--dark .red_rentab{
		background-color: #a0535b;
	}
	.theme--dark .orange_rentab{
		background-color: #7b7b4f;
	}
	.theme--dark .green_rentab{
		background-color: #4f7844;
	}
	.white_rentab{
		background-color: #fff !important;
	}
	.green_value{
        color: #08a008;
        font-weight: bold;
    }
    .red_value{
        color: #f56565;
        font-weight: bold;
    }
    .orange_value{
        color: #cc740e;
        font-weight: bold;
    }
    
</style>
<style lang="scss">
.bee-table-wrapper{
      max-width: calc(100vw - 47px);
    }
</style>
