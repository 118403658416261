import { HTTP } from './http'

export const SocialParser = {
    async ListSocialItem(query=null) {
        return HTTP.get('/social_parser/social_items/', {
            params: query
        })
    },
    async CreateSocialItem(form_data) {
        return HTTP.post('/social_parser/social_items/', form_data)
    },
    async SetDisableSocialItem(id) {
        return HTTP.post(`/social_parser/social_items/${id}/set_disable/`)
    },
    async TemplateImportSocialItem(){
        return HTTP.get('/social_parser/social_items/template_import_social_item/', {responseType:"blob"})
    },
    async ImportSocialItem(data){
        return HTTP.post('/social_parser/social_items/import_social_item/', data)
    },
    async ExportBaseSocialItem(query=null){
        return HTTP.get('/social_parser/social_items/base_export/', {responseType:"blob", params: query})
    },
    async ExportStatsSocialItem(query=null){
        return HTTP.get('/social_parser/social_items/history_export/', {responseType:"blob", params: query})
    },
    async CheckStatsSocialItem(id){
        return HTTP.post(`/social_parser/social_items/${id}/check_stats/`)
    },
    async DeleteStatsSocialItem(id){
        return HTTP.delete(`/social_parser/social_items/${id}/`)
    },
    async ListManagers(){
        return HTTP.get('/social_parser/social_items/list_managers/')
    },
    async addLikeeStats(id, data){
        return HTTP.post(`/social_parser/social_items/${id}/add_likee_stats/`, data)
    },
    async socialPermissions(){
        return HTTP.get(`/social_parser/social_items/get_social_permissions/`)
    },
    async socialStatsByProducts(query=null){
        return HTTP.get(`/social_parser/social_items/get_social_stats_by_products/`, {params: query})
    },
    async socialStatsByProductsExport(query=null){
        return HTTP.get(`/social_parser/social_items/export_social_stats_by_products/`, {responseType:"blob", params: query})
    },
    async socialStatsByManagers(query=null){
        return HTTP.get(`/social_parser/social_items/get_social_stats_by_managers/`, {params: query})
    },
    async socialStatsByManagersExport(query=null){
        return HTTP.get(`/social_parser/social_items/export_social_stats_by_managers/`, {responseType:"blob", params: query})
    },

    async ListSocialFinance(query=null) {
        return HTTP.get('/social_parser/social_finance/', {params: query})
    },
    async BrandListSocialFinance() {
        return HTTP.get('/social_parser/social_finance/brand_list/')
    },
    async CreateSocialFinance(data) {
        return HTTP.post(`/social_parser/social_finance/`, data)
    },
    async UpdateSocialFinance(id, data) {
        return HTTP.patch(`/social_parser/social_finance/${id}/`, data)
    },
    async DeleteSocialFinance(id) {
        return HTTP.delete(`/social_parser/social_finance/${id}/`)
    },
}