<template>
    <v-container fluid>
        <v-dialog v-model="create.dialog" max-width="600" v-if="create.dialog">
            <v-card class="p-5">
                <v-card-title>Добавление записи социальной сети</v-card-title>
                <v-card-text>
                    <v-autocomplete
                        v-model="create.form_data.manager" :items="create.manager_choices" class="mt-3"
                        label="Менеджер" outlined hide-details dense clearable item-text="name" item-value="id"
                    ></v-autocomplete>
                    <v-select
						v-model="create.form_data.resource" :items="create.resource_choices" class="mt-3"
						item-text="name" item-value="id" label="Площадка" outlined hide-details dense clearable
					></v-select>
                    <v-text-field
                        v-model="create.form_data.link" label="Ссылка на запись" 
                        outlined clearable dense class="mt-3" hide-details
                    ></v-text-field>
                    <v-autocomplete
                        outlined clearable label="Артикул WB" v-model="create.form_data.products"
                        :loading="create.loading_product_autocomplete" :items="create.products_choices"
                        item-text="nmid" item-value="id" :search-input.sync="create.search_products"
                        cache-items hide-details dense multiple class="mt-3"
                    ></v-autocomplete>
                    <v-text-field
                        v-model="create.form_data.name" label="Название ролика" 
                        outlined clearable dense class="mt-3" hide-details
                    ></v-text-field>
                    <v-menu 
                        v-model="datepicker_menu_for_create" :close-on-content-click="false" 
                        :nudge-right="40" transition="scale-transition" offset-y="offset-y" 
                        min-width="290px" ref="datepicker_menu_for_create"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                clearable @click:clear="create.form_data.date = null" dense v-on="on"
                                v-model="dateForCreatedFormatted" label="Дата создания ролика" 
                                readonly="readonly" v-bind="attrs" 
                                hide-details="auto" outlined class="mt-3"
                            ></v-text-field>
                        </template>
                        <v-date-picker
                            v-model="create.form_data.date" :first-day-of-week="1"
                            @input="datepicker_menu_for_create = false"
                        ></v-date-picker>
                    </v-menu>
                    <div>
                        <v-btn 
                            color="primary" class="mt-5" :loading="create.loading" @click="create_record"
                        >Создать запись</v-btn>
                        <div class="d-flex mt-3" v-if="permissions == 'super_manager' || permissions == 'director'">
                            <v-file-input
                                label="Файл с данными" outlined dense id="file_social_items" hide-details
                            ></v-file-input>
                            <v-btn 
                                color="primary" :loading="create.loading_file" @click="import_social_items" class="ml-5"
                            >Загрузить записи из файла</v-btn>
                        </div>
                        <p 
                            style="cursor:pointer" class="text-download mt-3" @click="template_import_social_item"
                            v-if="permissions == 'super_manager' || permissions == 'director'"
                        >
                            Скачать шаблон
                        </p>
                    </div>
                </v-card-text>
            </v-card>
        </v-dialog>
        <v-dialog v-model="deleted.dialog" max-width="600" v-if="deleted.dialog">
            <v-card class="p-5">
                <v-card-title>Вы уверены, что хотите удалить запись {{ deleted.editable_item.link }}</v-card-title>
                <v-card-text class="mt-5">
                    <v-btn color="error" @click="confirm_delete_item">
                        Удалить запись
                    </v-btn>
                </v-card-text>
            </v-card>
        </v-dialog>
        <v-dialog v-model="add_stats.dialog" max-width="600" v-if="add_stats.dialog">
            <v-card class="p-5">
                <v-card-title>Добавление статистики</v-card-title>
                <v-card-text>
                    <v-text-field
                        v-model="add_stats.views" label="Просмотры" outlined 
                        clearable dense class="mt-3" hide-details type="number"
                    ></v-text-field>
                    <v-text-field
                        v-model="add_stats.likes" label="Лайки" outlined 
                        clearable dense class="mt-3" hide-details type="number"
                    ></v-text-field>
                    <v-text-field
                        v-model="add_stats.comments" label="Комментарии" outlined 
                        clearable dense class="mt-3" hide-details type="number"
                    ></v-text-field>
                    <v-btn color="primary" class="mt-5" :loading="add_stats.loading" @click="add_likee_stats">
                        Создать запись
                    </v-btn>
                </v-card-text>
            </v-card>
        </v-dialog>
        <v-card>
            <v-card-title>
                Статистика социальных сетей
                <v-btn class="ml-5" color="primary" @click="open_dialog_create">
                    Создать запись
                </v-btn>
                <v-menu offset-y>
                    <template v-slot:activator="{ on }">
                        <v-btn class="ml-5" color="primary" v-on="on" :loading="export_base_loading | export_stats_loading">
                            Экспорты в эксель
                        </v-btn>
                    </template>
                    <v-list>
                        <v-list-item>
                            <v-btn color="primary" @click="export_base" :loading="export_base_loading">
                                Базовый экспорт социальных сетей
                            </v-btn>
                        </v-list-item>
                        <v-list-item>
                            <v-btn color="primary" @click="export_stats" :loading="export_stats_loading">
                                Экспорт статистики социальных сетей
                            </v-btn>
                        </v-list-item>
                    </v-list>
                </v-menu>
                <v-menu offset-y>
                    <template v-slot:activator="{ on }">
                        <v-btn class="ml-5" color="primary" v-on="on" :loading="export_base_loading | export_stats_loading">
                            Страницы статистики
                        </v-btn>
                    </template>
                    <v-list>
                        <v-list-item>
                            <router-link target="_blank" :to="{ name: 'social_parser_stats_product'}">
                                <v-btn color="primary">Статистика в разрезе товаров</v-btn>
                            </router-link>
                        </v-list-item>
                        <v-list-item>
                            <router-link target="_blank" :to="{ name: 'social_parser_stats_manager'}">
                                <v-btn color="primary">Статистика в разрезе менеджеров</v-btn>
                            </router-link>
                        </v-list-item>
                    </v-list>
                </v-menu>
                <router-link target="_blank" :to="{ name: 'social_parser_finance'}" class="ml-5">
                    <v-btn color="primary">Расходы социальных сетей</v-btn>
                </router-link>
            </v-card-title>
            <v-card-text ref="social_items_filters">
                <div class="d-flex mt-5">
                    <div style="display: flex; flex-direction: column;">
                        <v-menu 
                            v-model="datepicker_menu_create" :close-on-content-click="false" 
                            :nudge-right="40" transition="scale-transition" offset-y="offset-y" 
                            min-width="290px" ref="datepicker_menu_create"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    clearable v-model="dataCreateRangeText" label="Период добавления ролика"
                                    prepend-icon="mdi-calendar" readonly="readonly" 
                                    @click:clear="dates_create_range = []"
                                    v-bind="attrs" hide-details="auto" outlined dense v-on="on"
                                ></v-text-field>
                            </template>
                            <v-date-picker 
                                v-model="dates_create_range" range :first-day-of-week="1"
                            ></v-date-picker>
                        </v-menu>
                        <v-menu 
                            v-model="datepicker_menu_stats" :close-on-content-click="false" 
                            :nudge-right="40" transition="scale-transition" offset-y="offset-y" 
                            min-width="290px" ref="datepicker_menu_stats"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    clearable v-model="dataStatsRangeText" label="Период статистики"
                                    prepend-icon="mdi-calendar" readonly="readonly" class="mt-3"
                                    v-bind="attrs" hide-details="auto" outlined dense v-on="on"
                                    @click:clear="dates_stats_range = []"
                                ></v-text-field>
                            </template>
                            <v-date-picker 
                                v-model="dates_stats_range" range :first-day-of-week="1"
                            ></v-date-picker>
                        </v-menu>
                    </div>
                    <div style="display: flex; flex-direction: column;" class="ml-3">
                        <v-autocomplete
                            v-model="list.filters.tutor" :items="create.tutor_choices" multiple
                            label="Куратор" outlined hide-details dense clearable item-text="name" item-value="id"
                        ></v-autocomplete>
                        <v-autocomplete
                            v-model="list.filters.director" :items="create.directors_choices" multiple
                            label="Руководитель проекта" outlined hide-details dense clearable item-text="name" item-value="id"
                        ></v-autocomplete>
                    </div>
                    <v-autocomplete
                        v-model="list.filters.manager" :items="create.manager_choices" class="ml-3" multiple
                        label="Менеджер" outlined hide-details dense clearable item-text="name" item-value="id"
                    ></v-autocomplete>
                    <v-select
						v-model="list.filters.resource" :items="create.resource_choices" class="ml-3" multiple
						item-text="name" item-value="id" label="Площадка" outlined hide-details dense clearable
					></v-select>
                    <v-text-field
                        v-model="list.filters.products" label="Артикул" 
                        outlined clearable dense class="ml-3" hide-details
                    ></v-text-field>
                    <v-text-field
                        v-model="list.filters.name" label="Название" 
                        outlined clearable dense class="ml-3" hide-details
                    ></v-text-field>
                    <v-radio-group v-model="list.filters.disable" class="ml-3 mt-0 pt-0" column>
                        <v-radio :value="0" label="Все"></v-radio>
                        <v-radio :value="false" label="Активные"></v-radio>
                        <v-radio :value="true" label="Приостановленные"></v-radio>
                    </v-radio-group>
                    <v-btn 
                        color="primary" class="ml-5" :loading="list.loading" @click="list_records"
                    >Применить фильтры</v-btn>
                </div>
                <v-data-table
                    :headers="list.headers" :pageCount="list.pagination.numberOfPages" :items="list.data"
                    item-key="id" :loading="list.loading" :options.sync="list.options"
                    :server-items-length="list.pagination.totalItems" class="elevation-1 mt-5"
                    :footer-props="{'items-per-page-options': [10, 25]}"
                >
                    <template v-slot:body.prepend>
                        <tr style="font-weight: bold;" class="v-row-group__header">
                            <td colspan="7">Итого</td>
                            <td colspan="1" class="text-center">{{ summary.total_views }}</td>
                            <td colspan="1" class="text-center">{{ summary.views_part }}</td>
                            <td colspan="1" class="text-center">{{ summary.likes_part }}</td>
                            <td colspan="1" class="text-center">{{ summary.comments_part }}</td>
                            <td colspan="1"></td>
                        </tr>
                    </template>
                    <template v-slot:item.products="{ item }">
                        <div style="display: flex; flex-direction: column;" v-if="permissions != 'manager'">
                            <router-link 
                                v-for="sku in item.wb_products"
                                :key="sku"
                                target="_blank" 
                                :to="{ name: 'product_card', params: { id: sku }}"
                            >{{ sku }}</router-link>
                        </div>
                        <div style="display: flex; flex-direction: column;" v-else>
                            <div 
                                v-for="sku in item.wb_products"
                                :key="sku"
                            >{{ sku }}</div>
                        </div>
                    </template>
                    <template v-slot:item.action="{ item }">
                        <div style="display: flex; flex-direction: column; align-items: center;">
                            <v-btn 
                                @click="open_delete_dialog(item)" outlined x-small color="error"
                                v-if="permissions == 'super_manager' || permissions == 'director' || permissions == 'tutor' || permissions == 'manager'"
                            >Удалить запись</v-btn>
                            <v-btn 
                                @click="set_disable_record(item)" outlined x-small 
                                 v-if="permissions == 'super_manager' || permissions == 'director' || permissions == 'tutor' || permissions == 'manager'"
                                :color="!item.disable ? 'blue-grey' : 'success'" class="mt-3 d-block"
                            >{{ !item.disable ? 'остановить': 'запустить' }}</v-btn>
                            <v-btn 
                                @click="check_stats(item)" outlined x-small 
                                color="green" class="mt-3" v-if="permissions == 'super_manager'"
                            >Проверить статистику</v-btn>
                            <v-btn 
                                @click="open_dialog_add_stats(item)" outlined x-small 
                                color="purple" class="mt-3"
                            >Добавить запись статистики</v-btn> 
                        </div>                       
                    </template>
                    <template v-slot:item.resource="{ item }">
                        <div class="d-flex align-center justify-center">
                            {{ item.resource }}
                            <a :href="item.link" target="_blank">
                                <v-btn icon><v-icon>mdi-link-box-outline</v-icon></v-btn>
                            </a>
                        </div>
                    </template>
                    <template v-slot:item.total_likes_graph="{ item }">
                        <div style="text-align: center;">{{ item.likes_part }}</div>
                        <div style="max-width: 150px; width: 150px; margin: 0 auto;">
                            <SocialParserCharts :data="item.likes_array" :name="'Лайки'" :key="`likes_${item.id}_${counter}`"/>
                        </div>
                        
                    </template>
                    <template v-slot:item.total_views_graph="{ item }">
                        <div style="text-align: center;">{{ item.views_part }}</div>
                        <div style="max-width: 150px; width: 150px; margin: 0 auto;">
                            <SocialParserCharts :data="item.views_array" :name="'Просмотры'" :key="`views_${item.id}_${counter}`"/>
                        </div>
                    </template>
                    <template v-slot:item.total_comments_graph="{ item }">
                        <div style="text-align: center;">{{ item.comments_part }}</div>
                        <div style="max-width: 150px; width: 150px; margin: 0 auto;">
                            <SocialParserCharts :data="item.comments_array" :name="'Комментарии'" :key="`comments${item.id}_${counter}`"/>
                        </div>
                    </template>
                    <template v-slot:item.total_views="{ item }">
                        <div>{{ item.total_views }}</div>
                        <div v-if="item.resource == 'Instagram'">(уник: {{ item.total_unique_views }})</div>
                    </template>
                </v-data-table>
            </v-card-text>
        </v-card>
    </v-container>
</template>

<script>
    import { SocialParser } from '@/api/social_parser';
    import { Redemption } from '@/api/redemption'
    import _ from 'lodash'
    import SocialParserCharts from '@/views/SocialParser/SocialParserCharts.vue';

	export default {
		name: 'SocialParserBasePage',
        components: {
            SocialParserCharts
        },
		data() {
			return {
                counter: 0,
                deleted: {
                    dialog: false,
                    editable_item: null
                },
                create: {
                    dialog: false,
                    loading: false,
                    loading_file: false,
                    form_data: {
                        link: null,
                        products: null,
                        manager: null,
                        resource: null,
                        name: null,
                        date: null
                    },
                    loading_search_products: false,
                    manager_choices: [],
                    tutor_choices: [],
                    directors_choices: [],
                    resource_choices: [],
                    products_choices: [],
                    search_products: null
                },
                list: {
                    loading: false,
                    headers: [
						{ text: 'Дата добавления в сервис', value: 'date_created', width: '1%', sortable: true, align: 'center'},
                        { text: 'Дата создания', value: 'date', width: '1%', sortable: true, align: 'center'},
						{ text: 'Менеджер', value: 'manager', width: '1%', sortable: false, align: 'center' },
                        { text: 'Куратор', value: 'tutor', width: '1%', sortable: false, align: 'center' },
						{ text: 'Площадка', value: 'resource', width: '1%', sortable: true, align: 'center' },
						{ text: 'SKU', value: 'products', width: '1%', sortable: false, align: 'center' },
                        { text: 'Название', value: 'name', width: '3%', sortable: true, align: 'center' },
						{ text: 'Просмотры всего', value: 'total_views', width: '1%', sortable: true, align: 'center' },
						{ text: 'Просмотры(за период)', value: 'total_views_graph', width: '1%', sortable: true, align: 'center' },
						{ text: 'Лайки(за период)', value: 'total_likes_graph', width: '1%', sortable: true, align: 'center' },
						{ text: 'Комментарии(за период)', value: 'total_comments_graph', width: '1%', sortable: true, align: 'center' },
						{ text: 'Действия', value: 'action', width: '1%', sortable: false, align: 'center' },
					],
                    data: [],
                    options: {
                        sortBy: ['date_created'],
                        sortDesc: [true],
                        itemsPerPage: 25
                    },
                    pagination: {
                        next: null,
                        totalItems: 0,
                        numberOfPages: 0,
                        currentPage: 0
                    },
                    filters: {
                        manager: null,
                        tutor: null,
                        director: null,
                        resource: null,
                        products: null,
                        name: null,
                        disable: 0
                    }
                },
                add_stats: {
                    loading: false,
                    dialog: false,
                    item: null,
                    likes: 0,
                    comments: 0,
                    views: 0
                },
                datepicker_menu_create: false,
                datepicker_menu_stats: false,
                dates_create_range: [],
                dates_stats_range: [],

                datepicker_menu_for_create: false,
                export_base_loading: false,
                export_stats_loading: false,
                permissions: null,
                summary: {}
            }
		},
        watch: {
            'create.search_products': function(val) {
                if (val && val.length > 1){
                    this.create.loading_product_autocomplete = true
                    this.debouncedGetFilteredProduct(val)
                }
            },
            'list.options': async function (newVal, oldVal) {
				if (oldVal) {
					await this.list_records()
				}
			},
        },
        computed: {
            dataCreateRangeText() {
				return this.dates_create_range.join(' ~ ');
			},
            dataStatsRangeText() {
				return this.dates_stats_range.join(' ~ ');
			},
            transfromFiltersForQuery(){
                let query = {}
                for (const f in this.list.filters) {
                    if (f == 'disable' && this.list.filters.disable === 0)
                        continue
                    if (Array.isArray(this.list.filters[f])) {
                        query[f] = this.list.filters[f].join(',')
                    } else {
                        query[f] = this.list.filters[f]
                    }
                }
                return query
            },
            dateForCreatedFormatted () {
                return this.create.form_data.date ? this.$options.filters.formatDateToLocaleNoTime(this.create.form_data.date) : null
            },
            isSuperManager() {
				return this.$store.getters.isSuperManager || false;
			},
        },
		methods: {
            open_dialog_add_stats(item){
                this.add_stats.item = item
                this.add_stats.views = item.total_views
                this.add_stats.likes = item.total_likes
                this.add_stats.comments = item.total_comments
                this.add_stats.dialog = true
            },
            async open_dialog_create(){
                this.create.dialog = true
            },
            async fetch_manager_choices() {
                try {
                    const response = await SocialParser.ListManagers()
                    let data = response.data
                    this.create.manager_choices = data.managers
                    this.create.tutor_choices = data.tutors
                    this.create.resource_choices = data.resources
                    this.create.directors_choices = data.directors
                    let current_user = Array.from(this.create.manager_choices)
                                            .find(obj => obj.id == this.$store.getters.userPK)
                    if (current_user)
                        this.create.form_data.manager = current_user.id
                } catch (err) {
                    console.log(err.response)
                }
            },
            async add_likee_stats() {
                this.add_stats.loading = true
                try{
                    const json_data = {
                        "likes": this.add_stats.likes,
                        "views": this.add_stats.views,
                        "comments": this.add_stats.comments
                    }
                    await SocialParser.addLikeeStats(this.add_stats.item.id, json_data)
                    this.$toasted.show(`Статистика добавлена`, {
                        theme: 'bubble', type: 'success', position: 'top-center', duration: 3000,
                    });
                    await this.list_records()
                } catch (err){
                    this.$toasted.show(`Возникла ошибка(${err.response.data.error})`, {
						theme: 'bubble', type: 'error', position: 'top-center', duration: 3000,
                    });
                }
                this.add_stats.loading = false
                this.add_stats.dialog = false
                this.add_stats.item = null
            },
            async get_filters_products(val){
                try {
                    const response = await Redemption.fetchRedemptionFilteredProduct({'nmid': val})
                    this.create.products_choices = response.data
                } catch (err) {
                    console.log(err.data)
                }
                this.create.loading_product_autocomplete = false
            },
            async create_record(){
                this.create.loading = true
                try{
                    await SocialParser.CreateSocialItem(this.create.form_data)
                    this.$toasted.show(`Запись создана`, {
                        theme: 'bubble', type: 'success', position: 'top-center', duration: 3000,
                    });
                    this.create.dialog = false
                    await this.list_records()
                } catch (err){
                    this.$toasted.show(`Возникла ошибка(${err.response.data.error})`, {
						theme: 'bubble', type: 'error', position: 'top-center', duration: 3000,
                    });
                    console.log(err)
                }
                this.create.loading = false
            },
            async list_records(){
                
                this.list.loading = true
				this.$vuetify.goTo(this.$refs.social_items_filters, {
                    duration: 500, offset: 150, easing: 'linear',
                })
				const { page, itemsPerPage, sortBy, sortDesc } = this.list.options
				let pageNumber = page - 1
				try {
					let query_data = {
						...this.transfromFiltersForQuery,
						'offset': itemsPerPage * pageNumber,
						'limit': itemsPerPage,
						'sort': sortDesc[0] ? `-${sortBy[0]}` : sortBy[0]
					}
                    if (this.dates_create_range.length > 0){
                        if (this.dates_create_range.length == 1)
                            query_data['date_created_after'] = this.dates_create_range[0]
                        else {
                            query_data['date_created_after'] = this.dates_create_range[0]
                            query_data['date_created_before'] = this.dates_create_range[1]
                        }
                    }
                    if (this.dates_stats_range.length > 0){
                        if (this.dates_stats_range.length == 1)
                            query_data['date_stats_after'] = this.dates_stats_range[0]
                        else {
                            query_data['date_stats_after'] = this.dates_stats_range[0]
                            query_data['date_stats_before'] = this.dates_stats_range[1] 
                        }
                    }
                    const response = await SocialParser.ListSocialItem(query_data)
					this.list.data = response.data.results.data
                    this.summary = response.data.results.summary
                    this.counter += 1
					this.list.pagination.totalItems = response.data.count
					this.list.pagination.numberOfPages = Math.ceil(response.data.count / itemsPerPage)
				} catch (err) {
					console.log(err.data)
				}
				this.list.loading = false
            },
            async set_disable_record(item){
                let status = item.disable ? 'активировано' : 'приостановлено'
                try{
                    await SocialParser.SetDisableSocialItem(item.id)
                    this.$toasted.show(`Получение статистики записи ${status}`, {
                        theme: 'bubble', type: 'success', position: 'top-center', duration: 3000,
                    });
                    await this.list_records()
                } catch (err){
                    this.$toasted.show(`Возникла ошибка`, {
						theme: 'bubble', type: 'error', position: 'top-center', duration: 3000,
                    });
                    console.log(err)
                }
            },
            async import_social_items(){
                this.create.loading_file = true
				let fileToUpload = document.getElementById('file_social_items').files[0]
				let formData = new FormData()
				formData.append('fileToUpload', fileToUpload)
				try {
					await SocialParser.ImportSocialItem(formData)
                    this.$toasted.show(`Успешно`, {
                        theme: 'bubble', type: 'success', position: 'top-center', duration: 3000,
                    });
                    this.create.dialog = false
                    await this.list_records()
				}
				catch (err){
					this.$toasted.show(`${err.response.data.error}`, {
						theme: 'bubble', type: 'error', position: 'top-center', duration: 3000,
                    });
				}
				this.create.loading_file = false
			},
            async template_import_social_item(){
				try {
					const response = await SocialParser.TemplateImportSocialItem()
					const url = URL.createObjectURL(response.data)
					const link = document.createElement('a')
					link.href = url
					link.setAttribute('download', 'template_social_items.xlsx')
					document.body.appendChild(link)
					link.click()
					document.body.removeChild(link)
				} catch (err) {
					console.log(err.data)
				}
			},
            async export_base(){
                this.export_base_loading = true
				try {
					const response = await SocialParser.ExportBaseSocialItem(this.transfromFiltersForQuery)
					const url = URL.createObjectURL(response.data)
					const link = document.createElement('a')
					link.href = url
					link.setAttribute('download', 'social_items_base.xlsx')
					document.body.appendChild(link)
					link.click()
					document.body.removeChild(link)
				} catch (err) {
					console.log(err.data)
				}
                this.export_base_loading = false
			},
            async export_stats(){
                if (this.dates_stats_range.length != 2){
                    this.$toasted.show(`Заполните начальную и конечную дату статистики`, {
						theme: 'bubble', type: 'error', position: 'top-center', duration: 3000,
                    });
                    return
                }
                this.export_stats_loading = true
				try {
                    let query_data = {
                        ...this.transfromFiltersForQuery
                    }
                    if (this.dates_create_range.length > 0){
                        if (this.dates_create_range.length == 1)
                            query_data['date_created_after'] = this.dates_create_range[0]
                        else {
                            query_data['date_created_after'] = this.dates_create_range[0]
                            query_data['date_created_before'] = this.dates_create_range[1]
                        }
                        
                    }
                    if (this.dates_stats_range.length > 0){
                        if (this.dates_stats_range.length == 1)
                            query_data['date_stats_after'] = this.dates_stats_range[0]
                        else {
                            query_data['date_stats_after'] = this.dates_stats_range[0]
                            query_data['date_stats_before'] = this.dates_stats_range[1] 
                        }
                    }
					const response = await SocialParser.ExportStatsSocialItem(query_data)
					const url = URL.createObjectURL(response.data)
					const link = document.createElement('a')
					link.href = url
					link.setAttribute('download', 'social_items_stats.xlsx')
					document.body.appendChild(link)
					link.click()
					document.body.removeChild(link)
				} catch (err) {
                    console.log(err)
				}
                this.export_stats_loading = false
			},
            async check_stats(item){
                try{
                    const response = await SocialParser.CheckStatsSocialItem(item.id)
                    let data = response.data
                    this.$toasted.show(`Запрос выполнен успешно(Лайки = ${data.likes} / Просмотры = ${data.views} / Комментарии = ${data.comments} / Уник. просмотры = ${data.unique_views})`, {
                        theme: 'bubble', type: 'success', position: 'top-center', duration: 3000,
                    });
                } catch (err){
                    this.$toasted.show(`Запрос выполнен с ошибкой ${err.response.data.notes}`, {
						theme: 'bubble', type: 'error', position: 'top-center', duration: 3000,
                    });
                    console.log(err)
                }
            },
            open_delete_dialog(item){
                this.deleted.dialog = true
                this.deleted.editable_item = item
            },
            async confirm_delete_item(){
                try{
                    await SocialParser.DeleteStatsSocialItem(this.deleted.editable_item.id)
                    this.$toasted.show(`Запись удалена`, {
                        theme: 'bubble', type: 'success', position: 'top-center', duration: 3000,
                    });
                    await this.list_records()
                } catch (err){
                    this.$toasted.show(`Возникла ошибка`, {
						theme: 'bubble', type: 'error', position: 'top-center', duration: 3000,
                    });
                    console.log(err)
                }
                this.deleted.dialog = false
            },
            async get_permissions(){
                try{
                    const response = await SocialParser.socialPermissions()
                    this.permissions = response.data.permission
                } catch (err){
                    console.log(err)
                }
            }
		},
		async mounted() {
			document.title = 'Парсер социальных сетей';
			this.$store.commit('setPageTitle', `Парсер социальных сетей`)
            if (this.$route.query.resource)
                this.list.filters.resource = this.$route.query.resource
            if (this.$route.query.products)
                this.list.filters.products = this.$route.query.products
            if (this.$route.query.dates_create_range)
                this.dates_create_range = this.$route.query.dates_create_range
            if (this.$route.query.dates_stats_range)
                this.dates_stats_range = this.$route.query.dates_stats_range
            if (this.$route.query.manager)
                this.list.filters.manager = parseInt(this.$route.query.manager)
            await this.get_permissions()
            await this.list_records()
            await this.fetch_manager_choices()
		},
        created: function () {
            this.debouncedGetFilteredProduct = _.debounce(this.get_filters_products, 1000)
        }, 
	};
</script>

<style lang="scss">
</style>